$lvl2-backgroud: rgba(242, 245, 247, 1.0);
$content-default: rgba(19, 41, 63, 0.80);
$content-subtle: rgba(19, 41, 63, 0.65);

%content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

%content-box {
  border: 3px solid $lvl2-backgroud;
  background-color: $lvl2-backgroud;
  color: $content-default;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Helvetica', 'Arial', sans-serif;
}

hr {
  width: 100%;
}

h2,
h3 {
  margin: 0.5rem;
}

a {
  color: $content-default;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 1rem;

  >div {
    max-width: 500px;
  }
}

header {
  text-align: center;

  .logo {
    height: 100px;
  }

  margin-bottom: 1rem;
}

.agenda {
  @extend %content-container
}

.slot,
.break {
  margin-bottom: 1rem;
  width: 90%;
  border-radius: 0.25rem;
  padding: 0.7rem;
}

.slot {
  display: grid;
  grid-template-areas:
    "title title"
    "time presenter"
    "track presenter"
    "overview overview";
  grid-row-gap: 0.5rem;
  @extend %content-box;

  .time {
    grid-area: time;
    text-align: left;
  }

  .presenter {
    grid-area: presenter;
    text-align: right;
  }

  .title {
    grid-area: title;
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .overview {
    grid-area: overview;
    text-align: left;
    background-color: white;
    color: $content-default;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .track {
    grid-area: track;
    text-align: left
  }
}

.break {
  border: solid 2px black;
  font-weight: bold;
}


.userSelectContainer {
  @extend %content-container
}

.userSelect {
  margin-top: 2rem;
  text-align: center;
  background: $lvl2-backgroud;
  @extend %content-box;
  width: 90%;
  border-radius: 0.25rem;
  padding: 0.7rem;

  input {
    font-size: 1.2rem;
    border-radius: 0.2rem;
    border: 1px solid black;
    padding: 0.5rem;
    margin: 1rem;
  }
}

.userList {
  margin-top: 2rem;

  div {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  a {
    color: $content-default;

  }
}